
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import _ from "lodash";
import { loadAllData, loadData, updateBatch } from "./devices/helpers";
import BatchCard from "@/components/dashboard/batch-card.vue";
import BatchChart from "@/components/dashboard/batch-chart.vue";
import BatchIngredient from "@/components/dashboard/batch-ingredient.vue";
const keys = ["COMMENT", "INTERVENTION", "author"];
@Component({
  components: { BatchCard, BatchChart, BatchIngredient },
  computed: {},
  deactivated() {
    clearInterval(this.$store.state.loader);
  },
  async mounted() {
    this.$store.dispatch("loadBatches");
  },
  data: () => ({
    measurements_error: null,
    offset_hours: 0,
    input: {
      COMMENT: null,
      INTERVENTION: null,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
    },
    moment,
    show_debug: false,
  }),
  methods: {
    async isGoodEh(batch) {
      try {
        console.warn("batch is good!", batch);
        await updateBatch(
          batch._id,
          {
            "data.isGoodEh": "Good",
          },
          this.$store.getters.tb_api_headers
        );
        this.$store.dispatch("loadBatches");
      } catch (error) {
        alert("Failed to mark the batch good. Error: " + error);
      }
    },
    async badMalaka(batch) {
      try {
        console.warn("batch is bad!", batch);
        await updateBatch(
          batch._id,
          {
            "data.isGoodEh": "Bad",
          },
          this.$store.getters.tb_api_headers
        );
        this.$store.dispatch("loadBatches");
      } catch (error) {
        alert("Failed to mark the batch bad. Error: " + error);
      }
    },
    async loadData(batch) {
      try {
        console.warn("loading data", batch);
        await loadData(batch.id, {
          ...this.$store.getters.tb_api_headers,
        });
        this.$store.dispatch("loadBatches");
      } catch (error) {
        alert("Failed to load data. Error: " + error);
      }
    },
    async loadAllData(batch) {
      try {
        console.warn("loading data", batch);
        if (confirm("Thingsboard will have hard time, Are you sure?")) {
          await loadAllData({
            ...this.$store.getters.tb_api_headers,
          });
        }
      } catch (error) {
        alert("Failed to load data. Error: " + error);
      }
    },
  },
})
export default class ApEasyDens extends Vue {}
