
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  props: ["batches", "property", "format"],
  computed: {},
  data: () => ({}),
  methods: {},
})
export default class Breweries extends Vue {}
